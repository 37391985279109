<template>
  <main class="">
    <h4
      class="md:mt-3 hidden md:block md:text-sm md:text-left text-xs text-headerText"
    >
      View Salary / {{ empName() }}
    </h4>

    <div class="w-full flex justify-start mt-4">
      <easiBackButton />
    </div>

    <div
      class="mx-auto w-full flex flex-col gap-8 sm:w-10/12 md:w-8/12 mt-4 rounded-xl p-4"
    >
      <!-- Salary -->
      <ViewBenefits />
    </div>
  </main>
</template>

<script setup>
import ViewBenefits from "@/components/Payroll/View/Benefits/Benefits.vue";
import { ref, reactive, computed } from "vue";
import { storeToRefs } from "pinia";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";

const store = useDataStore();
const { getAllEmployees } = storeToRefs(store);
const route = useRoute();

const employee = computed(() => store.getEmployeeById(route.params.id));

function empName() {
  const fName = employee.value && employee.value.profile.firstName;
  const lName = employee.value && employee.value.profile.lastName;
  const fullName = `${fName} ${lName}`;
  return fullName;
}
const display = (arg) => {
  return arg ? arg : "N/A";
};
</script>

<style></style>
